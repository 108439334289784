
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins/Poppins-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins/Poppins-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

/* @import url('https://fonts.googleapis.com/css2?family=Poppins'); */
html,body{
  font-family: 'Poppins', sans-serif !important;
}
