@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bordered {
  border-color: #383838;
  border-width: 3px;
  border-style: solid;
}

.app-login-bg-left {
  height: 100%;
  background-color: @primary-color;
  width: 60%;
}

.customViewer {
  // border-color: #fa5b35;
  // border-width: 1px;
  // border-style: solid;
  // min-height: 20px;
  // max-height: 800px;
  // min-width: 20px;
  // max-width: 800px;
  // height: 100%;
  color: #fff;
}
.customCanvas {
  // height: 500px;
  // max-height: 500px;
  // min-width: 500px;
  // max-width: 500px;
  // overflow: auto;

  // min-height: 20px;
  // max-height: 800px;
  // min-width: 20px;
  // max-width: 800px;

  // height: '100vh';
  // width: 100%;

  // color: #fff !important;
}
.customPrevBtn {
  cursor: pointer;
  // display: inline-block;
  // margin: 0;
  // width: auto;
  // color: #fa5b35;
  // background-color: #f3f3f3;
  // border-color: #fa5b35;
  // border-width: 1px;
  // border-style: solid;
  // border-radius: 6px;

  color: #fff;
  background-color: transparent;
  border-width: 0px;
}
.customNextBtn {
  cursor: pointer;
  // display: flex;
  // margin: 0;
  // padding: 0;
  // margin-top: 5px;
  // width: auto;
  // color: #fa5b35;
  // background-color: #f3f3f3;
  // border-color: #333333;
  // border-width: 1px;
  // border-style: solid;
  // border-radius: 6px;

  // color: #fff;
  // background-color: transparent;
  // border-width: 0px;
  .icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
  }
}
.customResetBtn {
  cursor: pointer;
  display: inline-block;
  // margin: 0;
  // width: auto;
  color: #fff;
  background-color: transparent;
  // border-color: #45fa35;
  border-width: 0px;
  // border-style: solid;
  // border-radius: 6px;
}
.customPages {
  font-size: medium;
  // margin: 0.5rem;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  height: 100% !important;
}
.customWrapper {
  // height: 60px;
  // font-size: 24px;
  padding: 12px;
  // padding-bottom: 0;
  background-color: #000;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 60px;
  margin-right: 60px;
  border-radius: 10px;

  @media only screen and (max-width: 800px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.topicText {
  font-weight: 600;
  font-size: 20px;
  color: @app-title-text;
}

.secondText {
  font-weight: 600;
  font-size: 16px;
}

.wordBreak {
  word-break: break-all;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.lineHeight10 {
  line-height: 10px;
}

.height5 {
  height: 5px;
}

.height10 {
  height: 10px;
}

.circle {
  border-radius: 50%;
}

.round20 {
  border-radius: 20%;
}

.round10 {
  border-radius: 10%;
}

.displayInlineBlock {
  display: inline-block;
}

.transition2s {
  transition: 2s;
}

.center {
  text-align: center;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

/// font ///
.boldText {
  font-weight: 600;
}

.bold400 {
  font-weight: 400;
}
.bold500 {
  font-weight: 500;
}
.bold600 {
  font-weight: 600;
}

.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}
.font24 {
  font-size: 24px;
}
.font26 {
  font-size: 26px;
}
.font28 {
  font-size: 28px;
}

.responsive {
  font-size: 16px;

  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 10px;
  }

  @media only screen and (max-width: 300px) {
    font-size: 8px;
  }
}
/// font ///

/// padding ///
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
/// padding ///

/// padding-left ///
.pl5 {
  padding-left: 5px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.pl30 {
  padding-left: 30px;
}
.pl40 {
  padding-left: 40px;
}
/// padding-left ///

/// padding-right ///
.pr5 {
  padding-right: 5px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pr30 {
  padding-right: 30px;
}
.pr40 {
  padding-right: 40px;
}
/// padding-right ///

/// padding-top ///
.pt5 {
  padding-top: 5px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.pt30 {
  padding-top: 30px;
}
.pt40 {
  padding-top: 40px;
}
/// padding-top ///

/// padding-bottom ///
.pb5 {
  padding-bottom: 5px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb40 {
  padding-bottom: 40px;
}
/// padding-bottom ///

/// box ///
.box30 {
  height: 30px;
  width: 30px;
  line-height: 30px;
}
.box40 {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
/// box ///

/// bg ///
.bgGreen {
  background-color: #d1fff3;
}
/// bg ///

/// label ///
.labelGreen {
  color: #00b284;
}
.labelSign {
  color: #01ad6a;
}
/// label ///

.alert-enter {
  opacity: 1;
  transform: translate(100%, 0);
}
.alert-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 300ms;
}
.alert-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.alert-exit-active {
  opacity: 1;
  transform: translate(100%, 0);
  transition: opacity 0ms, transform 300ms;
}

.site-page-header {
  padding: 24px;
}

.site-page-header
  > .ant-page-header-heading
  > .ant-page-header-heading-left
  > .ant-page-header-heading-title {
  line-height: 40px;
}

//--------- PAGE COUNT -----------//
.page-count-panel {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.page-count-label {
  font-weight: 400;
  color: #777a89;

  > * {
    margin-bottom: 1em;
    color: @app-title-text;
  }
}

@primary-color: #122261;@secondary-color: #ffb100;@app-grey-1: #525461;@app-grey-2: #777A89;@app-grey-3: #9CA2B5;@app-grey-4: #BFC6DC;@app-grey-5: #E2E5ED;@app-grey-6: #F1F4FD;@app-bg-color: #F9FAFF;@app-white: #FFFFFF;@app-success: #01ad6a;@app-error: #E64646;@app-link: #DC8E00;@app-menu-selected: #ffb100;@app-title-text: #363740;@app-logo-url: /images/logo-pen.svg;@app-blur-bg-url: /images/bg-blur-login.svg;@app-login-bg-url: /images/bg-login-full.svg;@menu-item-active-bg: @app-grey-6;@menu-item-color: @app-grey-2;@layout-header-padding: 0 20px;@layout-header-height: 80px;@btn-border-radius-base: 4px;@background-color-light: @app-bg-color;@page-header-ghost-bg: @app-white;@page-header-heading-title: 30px;@border-radius-base: 4px;@breadcrumb-last-item-color: @primary-color;@table-font-size: 12px;@table-selected-row-bg: lighten(@primary-color, 73%);@link-color: @primary-color;@form-vertical-label-padding: 0 0 0px;@btn-disable-color: @app-grey-3;@btn-disable-bg: @app-grey-5;